import produce from 'immer';
import moment from 'moment'

const initialState = {
  merchantsAccounts: [],
  beneficiares: [],
  transferFee: [],
  transactionFee:[],
  storeRate:[],
  clients:[],
  subscriptions:[],
  gateway:[],
  currencies:[],
  exchange_rate:0,
  countries:[],
  merchantsCurrencyAccounts:[]
};

const transferReducer = (state = initialState, action) => {
  switch (action.type) { 
    case 'GET_COUNTRIES': return{...state,countries:action.payload}
    case 'SAVE_TRANSACTION_FEE':
       return {...state,transactionFee: action.payload}
    case 'SAVE_EXCHANGE_RATE':
       return {...state,exchange_rate: action.payload}
    case 'GET_CURRENCY':
       return {...state,currencies: action.payload}
    case 'GET_CLIENTS':
      return {...state,clients:action.payload}
     case 'ADD_CLIENTOBJ':
       return {
         ...state,
         clients:[...state.clients,action.payload]
       }
     case 'GET_GATEWAY':
       return {...state,gateway:action.payload}  
     case 'ADD_SUBS':
       //console.log('11',action.payload)
       return { ...state,subscriptions:[action.payload,...state.subscriptions]}
    case 'GET_SUBS':
      return {...state,subscriptions:action.payload}    
    case 'UPDATE_SUBS':
         const subscriptions =  produce(state.subscriptions,x=>{
            const i =  x.findIndex(e=>e.id==action.payload.id);
             x[i]={...x[i],...action.payload}
         })
        
      return {...state,subscriptions}      
    case 'STORE_EXCHANGE':
      return {...state,storeRate:action.payload}
    case 'UPDATE_TRANSFERFEE':
      return {...state,transferFee:state.transferFee.map(el=>(el.id==action.payload.id)?{...el,...action.payload}:el)}
    case 'ADD_TRASFERFEE':
      let id=state.transferFee.length+1;
      return { 
          ...state,
          transferFee: state.transferFee.push({...action.payload,id,created:moment(new Date()).format("YYYY-MM-DD HH:mm:ss")})
      }
    case "DEL_TRASFERFEE":
      return {
        ...state,
        transferFee: state.transferFee.filter((e) => e.id != action.payload),
      };
    case "STORE_TRANSFERFEE":
      return { ...state, transferFee: action.payload  };
    case "STORE_MERCHANTACCOUNTS":
      return { ...state, merchantsAccounts: action.payload };
    case "STORE_MERCHANTCURRENCYACCOUNTS":
      return { ...state, merchantsCurrencyAccounts: action.payload };
    case "STORE_BENEFICIARIES":
      return { ...state, beneficiares: action.payload.sort((a,b)=>b.id-a.id) };
    
    case "ADD_BENEF":
        return { beneficiares: [action.payload,...state.beneficiares],...state };  
    case "DEL_BENEF":
          return {...state, beneficiares: state.beneficiares.filter(el=>el.id!=action.payload) };     
    default:
      return state;
  }
};

export default transferReducer;
