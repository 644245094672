// **  Initial State
const initialState = {
  userData: {},
  adminDetails:{}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: action.data }
    case 'LOGOUT':
      return { ...state, userData: {} }
    
     case 'GET_USERDETAIL':
      return {...state,adminDetails:action.payload} 
      default:
      return state
 
    }
}

export default authReducer
