// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import merchant from "./storeMerchantDetails";
import services from "./smsServices"
import transfer from "./transfer";
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  merchant,
  services,
  transfer,
});

export default rootReducer;
