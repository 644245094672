import moment from "moment";
import produce from "immer";
// ** Initial State
const initialState = {
  merchants: [],
  transaction: [],
  error: "",
  loading: true,
  partners: [],
  partnerObj: {},
  roles: [],
  devices: [],
  types: [],
  subtypes: [],
  users: [],
  notifications: [],
  alldevices: [],
  loanData:[],
  loanList:[],
  transactiondata:[],
  merchant_users:[],
  currency:[],
  selectedloan:{},
  orders:[{data:false}],
  beneficiary:false,
  crown:[],
  products: []
};

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADDCROWN":{
      return {...state,crown:[{...action.payload.data,id:action.payload.id},...state.crown]}
    }
  case "UPDATECROWN":{
    let result=state.crown.map((ele)=>{
      if(ele.id==action.payload.id){
        return {...ele,...action.payload.data}
      }
      else{return ele}
    })
    return {...state,crown:result}
  }
    case "DELETECROWN":{
      let data=state.crown.filter((ele)=>{
        return ele.id!=action.payload
      })
      return {...state,crown:data}
    }
    case "CROWN":{
      return {...state,crown:action.payload}
    }
    case "BENEFICIARY":{
      return {...state,beneficiary:action.payload}
    }
    case "DELETEORDER":{
      let DeletedData=state.orders.filter((ele)=>{
        if(ele.purchase_order_id!=action.payload.order_id){
          return ele
        }
      })
      return {...state,orders:DeletedData}
    }
    case "UPDATEORDERDETAILS":{
      let result=state.orders.map((ele)=>{
        if(ele.purchase_order_id==action.payload.purchase_order_id){
          return {...ele,...action.payload}
        }
        else{return ele}
      })
      return {...state,orders:result}
    }
    case "UPDATEORDERS":{
      let updatedData=state.orders.map((ele)=>{
        if(ele.purchase_order_id==action.payload.purchase_order_id){
          return{...ele,payment_status:action.payload.payment_status}
        }
        
        else{return ele}
      })
      return {...state,orders:updatedData}
    }
    case "ORDERS":{
      return {...state,orders:action.payload}
    }
    case "SELECTEDLOAN":{
      return {...state,selectedloan:action.payload}
    }
    case "CURRENCY":{
      return {...state,currency:action.payload.currencies}
    }
    
    case "ADD_LOAN_TXN":{
      return {...state,transactiondata:[action.payload.txn,...state.transactiondata]}
    }
    
    case "GETTRANSACTIONS":{
      return {...state,transactiondata:action.payload}
    }
    case "ADDLOAN":{
      let data=state.loanList
      let result={...action.payload,status:"pending"}
      data=[result,...data]
      return {...state,loanList:data}
    }
    case "DELETEPLAN":{
      const data=state.loanData.filter((ele)=>{
        if(ele.id!=action.payload){
          return ele
        }
      })
      return {...state,loanData:data}
    }
    case "GET_MERCHANT_USER":{
      const {id,users} = action.payload;
      
      const merchant_users = state.merchant_users;
      merchant_users[id]=users;
      console.log(merchant_users);
      return {...state,merchant_users} 
    }
    case "ADD_MERCHANT_USER":{
       const merchant_users = state.merchant_users;
       const {merchant_id,user} = action.payload;
       console.log(user);
       const users = merchant_users[merchant_id]||[];
       users.push(user);
       merchant_users[merchant_id] =users.reverse();
       return {...state,merchant_users};
     }
    case "UPDATE_MERCHANT_USER":{ 
      const {merchant_id,id, user} = action.payload;
      const merchant_users = state.merchant_users;
      const users = merchant_users[merchant_id];
      const user_idx=  users.findIndex(el=>el.user_id==id);
      users[user_idx] = {...users[user_idx],...user};
      merchant_users[merchant_id]= users;
      return {...state,merchant_users};

      

    }
    case "UPDATELOAN":{
      if(action.payload.data.status){
        const data=state.loanList.map((ele)=>{
          if(ele.id==action.payload.id){
            return{...ele,status:action.payload.data.status}
          }
          else{return ele}
        })
        return {...state,loanList:data}
      }
      else{const data=state.loanList.map((ele)=>{
        if(ele.id==action.payload.id){
          return {...ele,...action.payload.data}
        }
        else{return ele}
      })
      return {...state,loanList:data}}
    }

    case "DELETELOAN":{
      const data=state.loanList.filter((ele)=>{
        if(ele.id!=action.payload){return ele}
      })
      return{...state,loanList:data}
    }

    case "LOANLIST":{
      return {...state,loanList:action.payload.loan_list}
    }

    case "GETPLAN":{
     return {...state,loanData:action.payload.plan_list}
    }

    case "UPDATE_PLAN":{
      const data=state.loanData.map((ele)=>{
        if(ele.id==action.payload.id){
          return {...ele,...action.payload.data}
        }
        else{return ele}
      })
      return {...state,loanData:data}
    }
    case "DEVICE":
      return {...state,alldevices:action.payload};

    case "UPDATE_MERCHANT_TYPE":
      const { id, params } = action.payload;
      //console.log(id,params)

      //console.log(obj2, action.payload, merchantclone);
      // let idx2 = merchantclone1.indexOf(obj2);

      return {
        ...state,
        merchants: state.merchants.map((el) =>
          el.merchant_id == id ? { ...el, ...params } : el
        ),
      };
      
    case "UPDATE_DEVICES": {
      if (action?.payload?.data?.device_id) {
        let data = state.devices.map((ele) => {
          if (ele.device_id == action.payload.data.device_id) {
            if (action.payload.data.status) {
              return { ...ele, status: action.payload.data.status };
            } else {
              return {
                ...ele,
                device_pin: action.payload.data.device_pin,
                payment_gateway: action.payload.data.payment_gateway,
                serial: action.payload.data.serial,
                mid: action.payload.data.mid,
                tid: action.payload.data.tid,
              };
            }
          } else {
            return ele;
          }
        });
        return { ...state, devices: data };
      } else {
        return { ...state, devices: action.payload };
      }
    }

    case "Add_DEVICE":{
      if(state.devices.length=0){
        state.devices.push(action?.payload?.data)
      }
    }
    
    case "UPDATE_PARTNER": {
      let obj = state.partners.find((el) => el.id == action.payload.id);
      obj = { ...obj, ...action.payload };
      const partnerArr = state.partners.filter(
        (el) => el.id != action.payload.id
      );

      return { ...state, parnters: [...partnerArr, obj] };
    }

    case "GET_ROLE":
      return { ...state, roles: action.payload };
    case "GET_TYPE":
      return { ...state, types: action.payload };
    case "GET_SUBTYPE":
      return { ...state, subtypes: action.payload };
    case "GET_USER":
      return { ...state, users: action.payload };
    case "UPDATE_USER":
      return {
        ...state,
        users: produce(state.users, (x) => {
          let i = x.findIndex((el) => el.id == action.payload.id);
          x[i] = { ...x[i], ...action.payload.params };
          //console.log(x[i])
        }),
      };

    case "UPDATE_ROLE":
      return {
        ...state,
        roles: state.roles.push({
          ...action.payload.data,
          id: state.roles.length + 1,
          create: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        }),
      };
    case "GET_NOTIFICATION":
      return { ...state, notifications: action.payload };
    case "DELETE_ROLE":
      return {
        ...state,
        roles: state.roles.filter((el) => el.id != action.payload),
      };
    case "UPDATE_MERCHANT":
      //  console.log(action.payload);
      return { ...state, merchants: action.payload };
    case "DEL_MERCHANT":
      return {
        ...state,
        merchants: state.merchants.filter(
          (el) => el.merchant_id != action.payload
        ),
      };
    case "GET_MERCHANT":
      return { ...state };
    case "ERROR_HANDLER":
      return { ...state, error: action, loading: false };

    case "GET_PARTNER":
      return { ...state, partners: action.payload };
    case "STORE_PARTNER_OBJ":
      let partnerObj = state.partners.find((res) => res.id == action.payload);
      return { ...state, partnerObj };
    case "CLR_PARTNER_OBJ":
      return { ...state, partnerObj: {} };
    case "TOGGLE_MERCHANT":
      let merchantclone = [...state.merchants];
      let obj = merchantclone.find((el) => el.merchant_id == action.payload.id);
      //console.log(obj, action.payload, merchantclone);
      let idx = merchantclone.indexOf(obj);
      merchantclone[idx]["status"] = action.payload.status ? 0 : 1;

      return { ...state, merchants: merchantclone };

    case "TOGGLE_PARTNER":
      let partnerclone = [...state.partners];
      let obj1 = partnerclone.find((el) => el.id == action.payload);
      //console.log(obj1);
      let idx1 = partnerclone.indexOf(obj1);
      partnerclone[idx1]["status"] ^= 1;

      return { ...state, partners: [...partnerclone] };
    default:
      return state;
  }
};

export default merchantReducer;
